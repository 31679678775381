@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background: #efefef;
}

@layer base {
  input,
  select,
  textarea {
    @apply border border-gray-500;
  }
  button {
    @apply outline-none border-none focus:outline-none filter hover:brightness-105 active:brightness-95;
  }
  a {
    @apply text-blue-600 hover:underline;
  }
  body {
    @apply font-spoqa;
  }
  .react-table-row-group > div {
    @apply table-row text-sm text-gray-500 overflow-x-auto hover:bg-blue-50 even:bg-gray-50;
  }
  .react-table-row-group > div > div {
    @apply table-cell px-6 py-4 align-top;
  }
}

@layer components {
  .wrapper {
    @apply p-4 m-4 bg-white rounded;
  }
  .wrapper-title {
    @apply font-bold text-2xl mb-3;
  }
  .link {
    @apply underline cursor-pointer;
  }
  .flex-2 {
    flex: 2 2 0%;
  }
  .flex-3 {
    flex: 3 3 0%;
  }
  table.border-table {
    border-collapse: separate;
    border-spacing: 0px;
    border-top: 1px solid #ededed;
    border-left: 1px solid #ededed;
  }
  table.border-table thead {
    background: #f6f6f6;
  }
  table.border-table th {
    border-right: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    background: #f6f6f6;
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    font-weight: 500;
  }
  table.border-table td {
    border-right: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    background: #fff;
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
  }
}

.input-number-hide-buttons input::-webkit-inner-spin-button,
.input-number-hide-buttons input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-number-hide-buttons input[type='number'] {
  -moz-appearance: textfield;
}

img.ant-image-preview-img {
  display: inline-block;
}

.ant-carousel .slick-dots li {
  border: 1px solid black;
  background: #3f3f3f;
  border-radius: 4px;
}

.ant-carousel .slick-next::before {
  color: red;
  background-color: red;
}

.ant-segmented-item {
  margin-bottom: 0;
}
