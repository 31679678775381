.Wrapper {
  background-color: #00000038;
}

.Inner {
  min-width: 553px;
  min-height: 460px;
  max-width: 553px;
  max-height: 460px;
  border-radius: 10px;
}

.Header {
  border-radius: 10px 10px 0px 0px;
}

.HeaderTitle {
  color: #FF6F61;
  margin: auto;
}

.Button {
  right: 0;
  padding-right: 10px;
  font-size: 20px;
}

.Input {
  width: 70%;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding: 4px 8px;
}

.Input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.TextArea {
  min-height: 120px;
  max-height: 120px;
}

.ResultButton {
  border-radius: 5px;
  padding: 11px 44px;
  color: white;
  margin-bottom: 40px;
}
